export function getScaledImageUrl(url) {
  if (!url) return null;
  
  // If URL contains @4x, scale it down by adjusting the width
  if (url.includes('@4x')) {
    const width = url.match(/width=(\d+)/);
    if (width && width[1]) {
      const scaledWidth = Math.round(parseInt(width[1]) / 4);
      return url.replace(/width=\d+/, `width=${scaledWidth}`);
    }
  }
  
  return url;
}