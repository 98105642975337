import React from 'react';

export const AtlassianLogo = () => (
  <svg width="114" height="36" viewBox="0 0 114 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[114px] h-full">
    <path d="M4.13203 17.4693C3.92109 17.2366 3.60466 17.2578 3.45699 17.5327L0.03961 24.4057C-0.0869601 24.6806 0.102895 24.9978 0.398224 24.9978H5.16569C5.31335 24.9978 5.46102 24.9132 5.5243 24.7652C6.57904 22.6293 5.94621 19.3937 4.13203 17.4693Z" fill="#5E5D5C"/>
    <path d="M6.63956 11.2118C4.71995 14.257 4.84651 17.6195 6.1122 20.1572C7.37793 22.6949 8.34826 24.6193 8.41159 24.7673C8.47486 24.9154 8.62253 25 8.77015 25H13.5376C13.833 25 14.0439 24.6828 13.8962 24.4078C13.8962 24.4078 7.4834 11.5501 7.3146 11.2329C7.20913 10.9368 6.82944 10.9157 6.63956 11.2118Z" fill="#5E5D5C"/>
    <path d="M70.7023 21.426C70.7023 19.4169 69.6265 18.4653 66.6308 17.8097C64.9644 17.4502 64.5636 17.0695 64.5636 16.5409C64.5636 15.8853 65.1546 15.5892 66.2516 15.5892C67.5804 15.5892 68.8881 15.991 70.133 16.562V13.9186C69.2679 13.4745 67.9179 13.1361 66.3355 13.1361C63.3615 13.1361 61.8214 14.4261 61.8214 16.562C61.8214 18.2538 62.602 19.6073 65.6817 20.1994C67.517 20.5801 67.8968 20.8761 67.8968 21.4894C67.8968 22.0815 67.517 22.4622 66.2093 22.4622C64.7115 22.4622 62.9184 21.9547 61.758 21.2568V24.0271C62.6865 24.4924 63.9097 24.9999 66.1882 24.9999C69.4158 24.9999 70.7023 23.5619 70.7023 21.426Z" fill="#5E5D5C"/>
    <path d="M103.148 13.3043V24.8298H105.595V16.0535L106.628 18.3797L110.088 24.8298H113.168V13.3043H110.721V20.7483L109.792 18.5912L107.008 13.3043H103.148Z" fill="#5E5D5C"/>
    <path d="M87.6008 13.3043H84.9214V24.8086H87.6008V13.3043Z" fill="#5E5D5C"/>
    <path d="M81.8194 21.4258C81.8194 19.4168 80.7436 18.4651 77.7478 17.8096C76.0815 17.4501 75.6806 17.0694 75.6806 16.5407C75.6806 15.8852 76.2717 15.5891 77.3681 15.5891C78.6975 15.5891 80.0052 15.9909 81.2501 16.5619V13.9184C80.3849 13.4743 79.035 13.136 77.4526 13.136C74.4786 13.136 72.9385 14.426 72.9385 16.5619C72.9385 18.2537 73.7191 19.6072 76.7987 20.1993C78.6341 20.5799 79.0138 20.876 79.0138 21.4893C79.0138 22.0814 78.6341 22.4621 77.3264 22.4621C75.8285 22.4621 74.0354 21.9546 72.8751 21.2567V24.027C73.803 24.4922 75.0268 24.9998 77.3053 24.9998C80.5534 24.9998 81.8194 23.5618 81.8194 21.4258Z" fill="#5E5D5C"/>
    <path d="M41.0637 13.3046V24.83H46.5484L47.4133 22.3346H43.7428V13.3046H41.0637Z" fill="#5E5D5C"/>
    <path d="M30.2002 13.3041V15.7995H33.1746V24.8295H35.8536V15.7995H39.039V13.3041H30.2002Z" fill="#5E5D5C"/>
    <path d="M26.2956 13.3169H22.7727L18.7646 24.8212H21.8234L22.393 22.8756C23.068 23.0659 23.7853 23.1928 24.5447 23.1928C25.283 23.1928 26.0002 23.0871 26.6964 22.8756L27.2659 24.8212H30.3247L26.2956 13.3169ZM24.5236 20.8243C24.0173 20.8243 23.511 20.7397 23.047 20.6128L24.5236 15.5797L26.0002 20.6128C25.5362 20.7397 25.051 20.8243 24.5236 20.8243Z" fill="#5E5D5C"/>
    <path d="M56.0399 13.3173H52.5171L48.509 24.8216H51.5678L52.1374 22.876C52.8124 23.0663 53.5296 23.1932 54.289 23.1932C55.0274 23.1932 55.7446 23.0875 56.4407 22.876L57.0103 24.8216H60.069L56.0399 13.3173ZM54.268 20.8247C53.7617 20.8247 53.2554 20.7401 52.7913 20.6132L54.268 15.5801L55.7446 20.6132C55.2805 20.7401 54.7742 20.8247 54.268 20.8247Z" fill="#5E5D5C"/>
    <path d="M97.2194 13.3173H93.696L89.6884 24.8216H92.7469L93.3162 22.876C93.9918 23.0663 94.709 23.1932 95.4679 23.1932C96.2063 23.1932 96.9235 23.0875 97.6196 22.876L98.1896 24.8216H101.248L97.2194 13.3173ZM95.4679 20.8247C94.962 20.8247 94.4555 20.7401 93.9918 20.6132L95.4679 15.5801L96.9447 20.6132C96.4599 20.7401 95.9745 20.8247 95.4679 20.8247Z" fill="#5E5D5C"/>
  </svg>
);