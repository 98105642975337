import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColesGroupLogo } from '../assets/logos/ColesGroupLogo';
import { AtlassianLogo } from '../assets/logos/AtlassianLogo';
import { AustraliaPostLogo } from '../assets/logos/AustraliaPostLogo';
import { UmbrellaLogo } from '../assets/logos/UmbrellaLogo';
import AuthOverlay from './AuthOverlay';
import { useProjectProtection } from '../helpers/useProjectProtection';
import { getThumbnailInsetClasses } from '../helpers/projectStyles';
import { fetchHomeData } from '../helpers/fetchHomeData';
import { getStorageUrl } from '../lib/supabase';

function ProjectList() {
  const [homeContent, setHomeContent] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate();
  const { 
    isProtected, 
    isVerified, 
    isBlocked, 
    blockedUntil, 
    attemptsRemaining, 
    verifyPin,
    checkProtectionStatus 
  } = useProjectProtection(selectedProject?.slug);

  useEffect(() => {
    async function loadData() {
      try {
        const { homeContent, projects } = await fetchHomeData();
        if (homeContent) {
          setHomeContent(homeContent);
        }
        if (projects) {
          setProjects(projects);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    if (selectedProject && isVerified) {
      navigate(`/project/${selectedProject.slug}`);
      setSelectedProject(null);
    }
  }, [isVerified, selectedProject, navigate]);

  const handleProjectClick = async (e, project) => {
    e.preventDefault();
    
    if (project.is_protected) {
      setSelectedProject(project);
      await checkProtectionStatus();
    } else {
      navigate(`/project/${project.slug}`);
    }
  };

  const handleVerify = async (pin) => {
    const success = await verifyPin(pin);
    return success;
  };

  if (loading) return null;
  if (!homeContent) return null;

  const getProjectBackground = (logo, slug) => {
    const baseClasses = "relative rounded-2xl border border-[rgba(52,52,52,0.08)] shadow-[0px_-10.414px_6.942px_-10.414px_rgba(74,74,65,0.04),0px_6.942px_20.827px_-13.885px_rgba(73,69,29,0.48)] overflow-hidden group-hover:border-[rgba(52,52,52,0.16)] transition-colors duration-150";
    switch (logo) {
      case 'coles':
        return `${baseClasses} bg-gradient-coles`;
      case 'atlassian':
        if(slug === 'customer-feedback-at-scale-solving-atlassians-feedback-problem') {
          return `${baseClasses} bg-gradient-atlassian-feedback`;
        }
        return `${baseClasses} bg-gradient-atlassian`;
      default:
        return `${baseClasses} bg-white`;
    }
  };

  const getLogo = (logo) => {
    switch (logo) {
      case 'coles':
        return <ColesGroupLogo />;
      case 'atlassian':
        return <AtlassianLogo />;
      case 'auspost':
        return <AustraliaPostLogo />;
      case 'umbrella':
        return <UmbrellaLogo />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="max-w-content mx-auto px-4 mt-[80px] sm:mt-[120px] lg:mt-[140px]">
        <div className="flex flex-col items-start gap-6 sm:gap-8 mb-[80px] sm:mb-[120px] lg:mb-[140px] px-0 sm:px-8 lg:px-16">
          <h1 className="heading-1">
            {homeContent.title}
          </h1>
          <h4 className="heading-subtitle text-center w-full">
            {homeContent.subtitle}
          </h4>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12 lg:gap-16 mb-[80px] sm:mb-[120px] lg:mb-[140px]">
          {projects.map((project) => (
            <a 
              key={project.id} 
              href={`/project/${project.slug}`}
              onClick={(e) => handleProjectClick(e, project)}
              className="group block min-w-0"
            >
              <div>
                <div className={getProjectBackground(project.logo, project.slug)}>
                  <div className="w-full pt-[60.9375%]" />
                  {project.thumbnail_url && (
                    <div className={getThumbnailInsetClasses(project.slug)}>
                      <img 
                        src={getStorageUrl(project.thumbnail_url)}
                        alt={project.title}
                        className="w-full h-auto image-rendering-crisp"
                      />
                    </div>
                  )}
                </div>
                <div className="mt-6">
                  {project.logo ? (
                    <div className="h-9 flex items-center mb-2">
                      {getLogo(project.logo)}
                    </div>
                  ) : (
                    <p className="body-small text-[#666666] mb-2">
                      {project.tagline}
                    </p>
                  )}
                  <h2 className="heading-3">
                    {project.tagline}: {project.title}
                  </h2>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>

      {selectedProject && isProtected && !isVerified && (
        <AuthOverlay
          title="Enter pin code"
          subtitle="This project is protected. Please enter the pin code to view it."
          isBlocked={isBlocked}
          blockedUntil={blockedUntil}
          attemptsRemaining={attemptsRemaining}
          onVerify={handleVerify}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </>
  );
}

export default ProjectList;