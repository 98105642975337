export function getImageContainerClasses(project) {
  // Base classes that should always be applied
  const baseClasses = "relative w-full overflow-hidden mb-[5rem] sm:mb-[7.5rem] lg:mb-[10rem]";
  const aspectRatio = "pt-[51.875%]"; // 1280x664 aspect ratio (664/1280)
  const borderShadowClasses = "rounded-[16px] border border-[rgba(52,52,52,0.08)] shadow-[0px_-12.683px_8.455px_-12.683px_rgba(74,74,65,0.04),0px_8.455px_25.366px_-16.911px_rgba(73,69,29,0.48)]";
  
  try {
    // Guard against null/undefined project
    if (!project) {
      console.warn('getImageContainerClasses: Project is null or undefined');
      return `${baseClasses} ${aspectRatio}`;
    }

    // Log project details for debugging
    console.debug('Project details:', {
      slug: project.slug,
      logo: project.logo,
      hasFullImage: !!project.full_image_url,
      hasPrototype: !!project.prototype_url
    });

    // Handle specific project types
    if (project.logo === 'atlassian') {
      if (project.slug === 'text-highlighting-for-confluence-shipping-longest-requested-feature') {
        return `${baseClasses} ${aspectRatio} ${borderShadowClasses} bg-gradient-text-highlighting`;
      }
      return `${baseClasses} ${aspectRatio} ${borderShadowClasses} bg-gradient-atlassian`;
    } 
    
    if (project.logo === 'coles') {
      return `${baseClasses} ${aspectRatio} ${borderShadowClasses} bg-gradient-coles`;
    }

    // Default fallback with border and shadow
    console.debug(`Using default styling for project with logo "${project.logo}"`);
    return `${baseClasses} ${aspectRatio} ${borderShadowClasses}`;

  } catch (error) {
    // Log detailed error but return safe default classes
    console.error('Error in getImageContainerClasses:', {
      error: error.message,
      projectId: project?.id,
      projectSlug: project?.slug,
      projectLogo: project?.logo,
      stack: error.stack
    });
    return `${baseClasses} ${aspectRatio} ${borderShadowClasses}`;
  }
}

export function getThumbnailInsetClasses(slug) {
  if (slug === 'text-highlighting-for-confluence-shipping-longest-requested-feature') {
    return 'absolute inset-0';
  }

  if (slug === 'scalable-ui-from-scratch-unlocking-multi-brand-growth-coles'){
    return 'absolute inset-0'
  }

  if (slug === 'customer-feedback-at-scale-solving-atlassians-feedback-problem'){
    return 'absolute inset-0'
  } 
  return 'absolute inset-4';
}

export function getPillarImageContainerClasses(index) {
  const baseClasses = "flex-1 flex";
  return index % 2 === 0 
    ? `${baseClasses} justify-end`
    : `${baseClasses} justify-start`;
}

export function getPillarImageClasses(index, slug) {
  const baseClasses = "w-full max-w-[calc(100vw-2rem)] rounded-lg s:w-full";

  if (slug === 'text-highlighting-for-confluence-shipping-longest-requested-feature') {
    if (index===0) {
      return `${baseClasses} xl:w-[33rem] lg:w-[29rem] md:w-[26rem] `;
    }
    if (index===1) {
      return `${baseClasses} xl:max-w-[23rem] lg:min-w-[21rem] md:w-[18rem]`;
    }
    return `${baseClasses} xl:max-w-[26rem] lg:min-w-[20rem] md:w-[24rem]`;
  }
  
  if (slug === 'customer-feedback-at-scale-solving-atlassians-feedback-problem') {
    if (index === 0) {
      return `${baseClasses} xl:w-[36rem] lg:w-[26rem] md:w-[24rem]`;
    }
    if (index === 1) {
      return `${baseClasses} xl:w-[39rem] lg:w-[31rem] md:w-[28rem]`;
    }
  }
  
  return baseClasses;
}

export function getPillarContainerClasses(index) {
  const baseClasses = "flex flex-col max-w-[68rem] md:flex-row gap-8 sm:gap-12 lg:gap-16 items-center";
  return index % 2 === 0 
    ? baseClasses 
    : `${baseClasses} md:flex-row-reverse`;
}

export function getPillarContentClasses(index, slug) {
  const baseClasses = "lg:max-w-[36rem] flex-shrink-0";

  if (slug === 'text-highlighting-for-confluence-shipping-longest-requested-feature' && index === 1) {
    return `${baseClasses} md:max-w-[34rem]`;
  }
  return `${baseClasses} md:max-w-[28rem] w-full`;
}

export function getVisualElementsClasses(slug) {
  const baseClasses = "lg:absolute lg:top-[1.5rem] -translate-y-1/2 z-0 svg-crisp block";
  
  if (slug === 'customer-feedback-at-scale-solving-atlassians-feedback-problem') {
    return `${baseClasses} lg:left-[-3rem] w-[16rem] -rotate-3`;
  }

  if (slug === 'text-highlighting-for-confluence-shipping-longest-requested-feature') {
    return `${baseClasses} lg:left-[-3rem] w-[8.75rem] lg:top-[2.5rem]`;
  }
  
  return `${baseClasses} lg:left-[-3rem] w-[8.75rem]`;
}