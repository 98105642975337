import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

const PROTECTION_KEY = 'project_protection';
const EXPIRY_TIME = 4 * 60 * 60 * 1000; // 4 hours

export function useProjectProtection(slug) {
  const [state, setState] = useState({
    isProtected: false,
    isVerified: false,
    isBlocked: false,
    blockedUntil: null,
    attemptsRemaining: 20,
    loading: true,
    error: null
  });

  const checkStoredVerification = () => {
    const stored = localStorage.getItem(PROTECTION_KEY);
    if (stored) {
      const { timestamp } = JSON.parse(stored);
      const isExpired = Date.now() - timestamp > EXPIRY_TIME;
      
      if (isExpired) {
        localStorage.removeItem(PROTECTION_KEY);
        return false;
      }
      return true;
    }
    return false;
  };

  const checkProtectionStatus = async () => {
    if (!slug) return;

    try {
      // Check if globally verified
      if (checkStoredVerification()) {
        setState(prev => ({ 
          ...prev,
          isVerified: true,
          loading: false 
        }));
        return;
      }

      // If not verified, check with server
      const { data, error } = await supabase.rpc('get_project_protection_status', { 
        project_slug: slug 
      });
      
      if (error) throw error;
      
      setState(prev => ({
        ...prev,
        isProtected: data.is_protected,
        isVerified: false,
        isBlocked: data.is_blocked,
        blockedUntil: data.blocked_until,
        attemptsRemaining: data.attempts_remaining,
        loading: false
      }));
    } catch (err) {
      console.error('Error checking protection status:', err);
      setState(prev => ({ 
        ...prev, 
        error: err.message,
        loading: false 
      }));
    }
  };

  useEffect(() => {
    if (!slug) return;
    checkProtectionStatus();
  }, [slug]);

  const verifyPin = async (pin) => {
    if (!slug) return false;

    try {
      const { data, error } = await supabase.rpc('verify_project_pin', { 
        project_slug: slug,
        input_pin: pin 
      });

      if (error) throw error;

      if (data) {
        // Store global verification
        localStorage.setItem(PROTECTION_KEY, JSON.stringify({
          timestamp: Date.now()
        }));
        
        setState(prev => ({
          ...prev,
          isVerified: true,
          isBlocked: false,
          blockedUntil: null,
          attemptsRemaining: 20
        }));
      }

      return data;
    } catch (err) {
      console.error('Error verifying pin:', err);
      setState(prev => ({ ...prev, error: err.message }));
      return false;
    }
  };

  return {
    ...state,
    verifyPin,
    checkProtectionStatus
  };
}