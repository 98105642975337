import React, { useState, useRef, useEffect } from 'react';

function LoadingSpinner() {
  return (
    <div className="inline-block h-6 w-6 animate-spin rounded-full border-2 border-solid border-[#25211A] border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
  );
}

function AuthOverlay({ 
  title,
  subtitle,
  isBlocked,
  blockedUntil,
  attemptsRemaining,
  onVerify,
  onClose 
}) {
  const [pin, setPin] = useState(['', '', '', '']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    requestAnimationFrame(() => {
      setIsVisible(true);
    });

    const timer = setTimeout(() => {
      inputRefs[0].current?.focus();
    }, 300);

    const handleEsc = (e) => {
      if (e.key === 'Escape' && !isSubmitting && onClose) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      document.body.style.overflow = '';
      window.removeEventListener('keydown', handleEsc);
      clearTimeout(timer);
    };
  }, [onClose, isSubmitting]);

  const handleChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;

    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);
    setError(null);

    // Move to next input if value is entered
    if (value && index < 3) {
      inputRefs[index + 1].current?.focus();
    }

    // Submit if all digits are entered
    if (value && index === 3) {
      handleSubmit(newPin);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      if (!pin[index] && index > 0) {
        // Move to previous input if current is empty
        inputRefs[index - 1].current?.focus();
      } else {
        // Clear current input
        const newPin = [...pin];
        newPin[index] = '';
        setPin(newPin);
      }
    }
  };

  const handleSubmit = async (pinArray) => {
    const pinCode = pinArray.join('');
    if (pinCode.length !== 4) return;

    setIsSubmitting(true);
    setError(null);

    try {
      const success = await onVerify(pinCode);
      if (!success) {
        setError('Incorrect pin code');
        setPin(['', '', '', '']);
        inputRefs[0].current?.focus();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const getBlockedMessage = () => {
    if (!blockedUntil) return null;
    const minutes = Math.ceil((new Date(blockedUntil) - new Date()) / 60000);
    return `Too many attempts. Please try again in ${minutes} minutes.`;
  };

  return (
    <div className="fixed inset-0 z-[100]">
      <div 
        className={`fixed inset-0 bg-[#ECECE8] transition-opacity duration-300 ${
          isVisible ? 'opacity-80' : 'opacity-0'
        }`} 
      />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div 
            className={`relative w-full max-w-[480px] py-12 sm:py-16 px-6 sm:px-12 lg:px-16 space-y-8 sm:space-y-10 text-center rounded-lg border border-[rgba(52,52,52,0.32)] bg-[#FCFCFA] shadow-[0px_-10.414px_6.942px_-10.414px_rgba(74,74,65,0.04),0px_6.942px_20.827px_-13.885px_rgba(73,69,29,0.48),0px_6.942px_90.83px_-20.88px_rgba(38,36,14,0.30)] transition-all duration-300 ${
              isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            }`}
          >
            <div className="space-y-4">
              <div className="flex items-center justify-center gap-4">
                <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-7 sm:w-[27px] sm:h-8">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.16696 8.53455C8.16676 7.3809 8.54066 6.25829 9.23256 5.33514C9.92446 4.412 10.8971 3.73811 12.0044 3.41458C13.1118 3.09105 14.2942 3.13532 15.3743 3.54076C16.4543 3.9462 17.3738 4.69094 17.9947 5.66324C18.2233 6.0208 18.5845 6.27292 18.999 6.36413C19.4135 6.45535 19.8472 6.37818 20.2047 6.14961C20.5623 5.92104 20.8144 5.55979 20.9056 5.14533C20.9968 4.73087 20.9197 4.29716 20.6911 3.93959C19.6975 2.38405 18.2263 1.19263 16.4982 0.544077C14.7701 -0.104475 12.8783 -0.175187 11.1066 0.34255C9.33489 0.860286 7.77884 1.93855 6.67189 3.41558C5.56494 4.89261 4.96677 6.68876 4.96713 8.53455V12.801H3.89838C3.05011 12.8016 2.23676 13.1389 1.63714 13.739C1.03752 14.339 0.700683 15.1526 0.700684 16.0008V28.8002C0.700684 29.6488 1.03781 30.4627 1.63789 31.0628C2.23798 31.6629 3.05187 32 3.90052 32H23.0995C23.9482 32 24.7621 31.6629 25.3621 31.0628C25.9622 30.4627 26.2994 29.6488 26.2994 28.8002V16.0008C26.2994 15.1522 25.9622 14.3383 25.3621 13.7382C24.7621 13.1381 23.9482 12.801 23.0995 12.801H8.16696V8.53455ZM6.56705 16.0008H3.90052V28.8002H23.0995V16.0008H6.56705Z" fill="#201A19"/>
                </svg>
                <h2 className="heading-2">
                  Access Restricted
                </h2>
              </div>
              <p className="heading-subtitle">
                Enter PIN to view project
              </p>
            </div>

            {isBlocked ? (
              <div className="text-[#D9224B] text-[16px] sm:text-[18px]">{getBlockedMessage()}</div>
            ) : (
              <>
                <div className="flex justify-center gap-2 sm:gap-4">
                  {pin.map((digit, index) => (
                    <input
                      key={index}
                      ref={inputRefs[index]}
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      className="w-[48px] h-[56px] sm:w-[54px] sm:h-[65px] flex items-center justify-center text-center font-['IBM_Plex_Sans'] text-[20px] sm:text-[24px] font-medium text-[#554D3F] rounded border border-[rgba(52,52,52,0.54)] bg-[#FEFEFE] focus:border-[#343434] focus:border-2 focus:outline-none disabled:bg-gray-100"
                      disabled={isSubmitting}
                    />
                  ))}
                </div>

                {isSubmitting && (
                  <div className="flex justify-center mt-4">
                    <LoadingSpinner />
                  </div>
                )}

                {error && (
                  <div className="text-[#D9224B] text-[16px] sm:text-[18px]">
                    {error}
                    {attemptsRemaining < 20 && (
                      <div className="text-[14px] sm:text-[16px] mt-1">
                        {attemptsRemaining} attempts remaining
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {!isSubmitting && onClose && (
              <button 
                onClick={onClose}
                className="font-['IBM_Plex_Sans'] text-[14px] sm:text-[16px] font-semibold leading-[20px] sm:leading-[24px] text-[rgba(37,33,26,0.80)] hover:text-[#25211A] hover:underline transition-colors"
              >
                Go back
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthOverlay;