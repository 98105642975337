import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase, getStorageUrl } from '../lib/supabase';
import ReactMarkdown from 'react-markdown';
import AuthOverlay from './AuthOverlay';
import { useProjectProtection } from '../helpers/useProjectProtection';
import { usePrototype } from '../helpers/usePrototype';
import { useDocumentTitle } from '../helpers/useDocumentTitle';
import { fetchProjectData } from '../helpers/fetchProjectData';
import { 
  getImageContainerClasses,
  getPillarContainerClasses,
  getPillarContentClasses,
  getPillarImageContainerClasses,
  getPillarImageClasses,
  getVisualElementsClasses
} from '../helpers/projectStyles';

function Accordion({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="py-1">
      <button
        className="w-full py-2 flex items-center text-left group"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          className={`transform transition-transform ${isOpen ? 'rotate-90' : ''} mr-[16px]`}
        >
          <path 
            d="M8 4L16 12L8 20"
            fill="#25211A"
          />
        </svg>
        <span className="heading-2 group-hover:text-[#666666] transition-colors">
          {title}
        </span>
      </button>
      {isOpen && (
        <div className="pl-10 pt-2 pb-4">
          <div className="body-large">
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

function MarkdownBlock({ children }) {
  const paragraphs = children.split('\n\n');

  return (
    <>
      {paragraphs.map((paragraph, index) => (
        <div key={index} className={index > 0 ? 'mt-10' : ''}>
          <ReactMarkdown
            components={{
              p: ({ children }) => <div>{children}</div>,
              strong: ({ children }) => (
                <strong className="font-bold">{children}</strong>
              ),
              em: ({ children }) => (
                <span className="underline decoration-[3px]">{children}</span>
              ),
              a: ({ href, children }) => (
                <a href={href} className="text-[#25211A] underline hover:text-[#666666] transition-colors" target="_blank" rel="noopener noreferrer">
                  {children}
                </a>
              )
            }}
          >
            {paragraph}
          </ReactMarkdown>
        </div>
      ))}
    </>
  );
}

function ProjectDetail() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [pillars, setPillars] = useState([]);
  const [toggles, setToggles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  
  const { 
    showPrototype,
    prototypeLoaded,
    prototypeReady,
    prototypeRef,
    handlePrototypeLoad
  } = usePrototype(project?.prototype_url);

  const { 
    isProtected, 
    isVerified, 
    isBlocked, 
    blockedUntil, 
    attemptsRemaining, 
    verifyPin,
    loading: protectionLoading,
    checkProtectionStatus
  } = useProjectProtection(slug);

  useDocumentTitle(project);

  useEffect(() => {
    if (slug) {
      checkProtectionStatus();
    }
  }, [slug]);

  useEffect(() => {
    if (!protectionLoading && (!isProtected || isVerified)) {
      fetchProjectData({
        slug,
        setProject,
        setPillars,
        setToggles,
        setLoading,
        setError
      });
    }
  }, [isProtected, isVerified, protectionLoading]);

  if (protectionLoading) return null;

  if (isProtected && !isVerified) {
    return (
      <AuthOverlay
        title="Access Restricted"
        subtitle="Enter PIN to view project"
        isBlocked={isBlocked}
        blockedUntil={blockedUntil}
        attemptsRemaining={attemptsRemaining}
        onVerify={verifyPin}
        onClose={() => navigate('/')}
      />
    );
  }

  if (loading) return null;

  if (error || !project) return <div>Project not found</div>;

  return (
    <div className="w-full px-4 sm:px-6 mt-[2.5rem] lg:mt-[8.75rem]">
      <div className="max-w-[68rem] mx-auto flex flex-col items-start mb-[5.5rem] sm:mb-[7.5rem] lg:mb-[8.75rem] px-0 sm:px-8 lg:px-16">
        <h4 className="heading-4 text-center w-full mb-[2rem]">
          {project.tagline}
        </h4>
        <h1 className="heading-1 mb-[2.5rem]">
          {project.title}
        </h1>
        <h4 className="heading-subtitle text-center w-full">
          <MarkdownBlock>{project.subtitle}</MarkdownBlock>
        </h4>
      </div>

      <div className="max-w-[80rem] mx-auto">
        <div className={getImageContainerClasses(project)}>
          <div className="absolute border-offset">
            {project.prototype_url && showPrototype && (
              <iframe
                ref={prototypeRef}
                src={project.prototype_url}
                width="100%"
                height="100%"
                allowFullScreen
                className={`absolute inset-0 w-full h-full transition-[z-index] duration-150 ${
                  prototypeReady ? 'z-10' : 'z-0'
                }`}
                onLoad={handlePrototypeLoad}
              />
            )}
            {project.full_image_url && (
              <img 
                src={getStorageUrl(project.full_image_url)}
                alt={project.title}
                className={`absolute inset-0 w-full h-full object-scale-down transition-[z-index] duration-150 ${
                  prototypeReady ? 'z-0' : 'z-10'
                }`}
              />
            )}
          </div>
        </div>
      </div>

      <div className="max-w-[68rem] mx-auto">
        <div className="relative flex flex-col h-auto sm:h-[17.625rem] py-[3rem] sm:py-[4rem] justify-center items-center gap-[0.625rem] self-stretch mb-[5rem] sm:mb-[7.5rem] lg:mb-[10rem]">
          {project.visual_elements_url && (
            <img 
              src={getStorageUrl(project.visual_elements_url)}
              alt="Visual elements"
              className={getVisualElementsClasses(project.slug)}
            />
          )}
          <div className="heading-2 text-center relative z-10">
            <MarkdownBlock>{project.overview}</MarkdownBlock>
          </div>
        </div>

        <div className="space-y-[5rem] sm:space-y-[7.5rem] lg:space-y-[10rem] mb-[5rem] sm:mb-[7.5rem] lg:mb-[10rem]">
          {pillars.map((pillar, index) => {
            return (
              <div key={index} className={getPillarContainerClasses(index)}>
                <div className={getPillarContentClasses(index, project.slug)}>
                  <span className="block heading-4 mb-4">
                    {String(index + 1).padStart(2, '0')}
                  </span>
                  <h3 className="heading-2 mb-6">
                    <MarkdownBlock>{pillar.title}</MarkdownBlock>
                  </h3>
                  <div className="body-large">
                    <MarkdownBlock>{pillar.description}</MarkdownBlock>
                  </div>
                </div>
                <div className={getPillarImageContainerClasses(index)}>
                  {pillar.image_url && (
                    <img 
                      src={getStorageUrl(pillar.image_url)}
                      alt={pillar.title}
                      className={getPillarImageClasses(index, project.slug)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div className="mx-auto mb-[5rem] sm:mb-[7.5rem] lg:mb-[10rem]">
          <h1 className="heading-1 mb-16">
            <MarkdownBlock>{project.conclusion_quote}</MarkdownBlock>
          </h1>

          {toggles && toggles.length > 0 && (
              <div className="mx-auto">
                <div className="space-y-1">
                  {toggles.map((toggle, index) => (
                    <Accordion key={index} title={toggle.heading}>
                      <ul className="space-y-4">
                        {toggle.content.map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <MarkdownBlock>{item}</MarkdownBlock>
                          </li>
                        ))}
                      </ul>
                    </Accordion>
                  ))}
                </div>
              </div>
           )}
          
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail;