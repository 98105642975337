import { useState, useEffect, useRef } from 'react';

export function usePrototype(prototypeUrl) {
  const [showPrototype, setShowPrototype] = useState(false);
  const [prototypeLoaded, setPrototypeLoaded] = useState(false);
  const [prototypeReady, setPrototypeReady] = useState(false);
  const prototypeRef = useRef(null);
  const errorRetryCount = useRef(0);
  const maxRetries = 3;

  useEffect(() => {
    if (prototypeUrl) {
      setShowPrototype(true);

      const handleMessage = (event) => {
        // Only handle messages from Figma
        if (!event.origin.includes("figma.com")) return;

        try {
          // Handle 401 errors silently
          if (event.data?.type === 'ERROR' && event.data?.status === 401) {
            return;
          }

          // We only care about INITIAL_LOAD and NEW_STATE events
          if (event.data?.type === 'INITIAL_LOAD') {
            setPrototypeLoaded(true);
            
            // Send ready message with error handling
            if (prototypeRef.current?.contentWindow) {
              try {
                prototypeRef.current.contentWindow.postMessage(
                  { type: 'READY' }, 
                  "https://www.figma.com"
                );
              } catch (err) {
                // Silently handle postMessage errors
                console.debug('Failed to send ready message to Figma prototype:', err);
              }
            }

            // Show prototype after a delay to ensure smooth transition
            setTimeout(() => setPrototypeReady(true), 500);
          }
          
          // Ensure prototype is visible after any state changes
          if (event.data?.type === 'NEW_STATE' && !prototypeReady) {
            setPrototypeReady(true);
          }
        } catch (err) {
          // Silently handle any parsing errors
          console.debug('Error handling Figma prototype message:', err);
          setPrototypeReady(true);
        }
      };

      // Handle iframe load errors
      const handleError = () => {
        if (errorRetryCount.current < maxRetries) {
          errorRetryCount.current++;
          console.debug(`Retrying prototype load (attempt ${errorRetryCount.current}/${maxRetries})`);
          
          // Retry after a short delay
          setTimeout(() => {
            if (prototypeRef.current) {
              prototypeRef.current.src = prototypeUrl;
            }
          }, 1000 * errorRetryCount.current); // Exponential backoff
        } else {
          // After max retries, show the prototype anyway
          console.debug('Max retries reached, showing prototype');
          setPrototypeReady(true);
        }
      };

      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
        errorRetryCount.current = 0;
      };
    }
  }, [prototypeUrl]);

  const handlePrototypeLoad = () => {
    // Reset error count on successful load
    errorRetryCount.current = 0;
  };

  return {
    showPrototype,
    prototypeLoaded,
    prototypeReady,
    prototypeRef,
    handlePrototypeLoad
  };
}