import { createClient } from '@supabase/supabase-js';
import { getScaledImageUrl } from './imageHelpers';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export function getStorageUrl(path) {
  if (!path) return null;
  
  // If it's already a full URL with a token, return scaled version
  if (path.includes('token=')) {
    return getScaledImageUrl(path);
  }

  // Get public URL for the file
  const url = supabase.storage
    .from('assets')
    .getPublicUrl(path.replace(/^\/+/, ''))
    .data.publicUrl;

  return getScaledImageUrl(url);
}