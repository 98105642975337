import { supabase } from '../lib/supabase';

export async function fetchHomeData() {
  try {
    // Get home content (title and subtitle)
    const { data: homeData } = await supabase
      .from('home_content')
      .select(`
        title,
        subtitle
      `)
      .single();

    // Get published projects in display order with specific fields
    const { data: projectsData } = await supabase
      .from('published_projects_by_order')
      .select(`
        id,
        title,
        tagline,
        logo,
        slug,
        thumbnail_url,
        is_protected
      `);

    return {
      homeContent: homeData || null,
      projects: projectsData || []
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return {
      homeContent: null,
      projects: []
    };
  }
}