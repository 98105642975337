import { supabase } from '../lib/supabase';

export async function fetchProjectData({ 
  slug,
  setProject,
  setPillars,
  setToggles,
  setLoading,
  setError 
}) {
  try {
    setLoading(true);
    setError(false);

    // Use published_projects_by_order view to get project data
    const { data: projectData, error: projectError } = await supabase
      .from('published_projects_by_order')
      .select(`
        id,
        slug,
        logo,
        title,
        subtitle,
        tagline,
        overview,
        full_image_url,
        prototype_url,
        conclusion_quote,
        visual_elements_url,
        is_published,
        project_content (
          id,
          content_type,
          title,
          description,
          image_url,
          order_num,
          is_published
        ),
        project_toggles (
          id,
          heading,
          content,
          order_num,
          is_published
        )
      `)
      .eq('slug', slug)
      .single();

    if (projectError) throw projectError;
    if (!projectData) {
      setError(true);
      return;
    }

    setProject(projectData);

    const projectPillars = projectData.project_content
      .filter(content => content.content_type === 'pillar' && content.is_published)
      .sort((a, b) => a.order_num - b.order_num);
    setPillars(projectPillars);

    const projectToggles = projectData.project_toggles
      ?.filter(toggle => toggle.is_published)
      .sort((a, b) => a.order_num - b.order_num) || [];
    setToggles(projectToggles);

  } catch (error) {
    console.error('Error fetching project:', error);
    setError(true);
  } finally {
    setLoading(false);
  }
}
