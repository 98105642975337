import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="py-8">
      <div className="max-w-content mx-auto px-6">
        <div className="flex justify-between items-center">
          <Link to="/" className="w-auto h-10">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-auto h-full" viewBox="0 0 144 40" fill="none">
              <path d="M7.49049 30.6027H0V10.7865H4.44491L5.76192 12.8294C6.74967 12.2029 7.95693 11.6037 9.38369 11.0317C10.8105 10.4324 12.3195 10.1328 13.9109 10.1328C15.612 10.1328 16.9839 10.4733 18.0266 11.1542C19.0966 11.808 19.8786 12.7205 20.3725 13.8917C20.8938 15.0358 21.1545 16.3432 21.1545 17.8141V30.6027H13.664V18.5087C13.664 17.8822 13.5131 17.3374 13.2113 16.8744C12.9094 16.3841 12.347 16.1389 11.5238 16.1389C11.0574 16.1389 10.4126 16.2615 9.58947 16.5066C8.79378 16.7518 8.09412 17.0106 7.49049 17.2829V30.6027Z" fill="#25211A"/>
              <path d="M48.0188 30.6027H41.1868L38.0589 20.3473L34.9722 30.6027H28.1402L22.049 12.0123L28.9633 10.0511L31.8443 22.0225L34.6018 10.7865H41.6396L44.6028 22.0225L47.1957 10.7865H54.645L48.0188 30.6027Z" fill="#25211A"/>
              <path d="M63.1607 30.6027H55.6702V10.8274H59.4154L61.2263 13.238C61.7751 12.8567 62.4198 12.4889 63.1607 12.1348C63.9289 11.7535 64.7246 11.4402 65.5477 11.1951C66.3983 10.9227 67.194 10.7865 67.9348 10.7865L69.4576 16.7518C68.4698 16.7518 67.3861 16.9561 66.2062 17.3647C65.0264 17.7732 64.0112 18.3044 63.1607 18.9581V30.6027Z" fill="#25211A"/>
              <path d="M74.1871 8.08989C73.0896 8.08989 72.143 7.69493 71.3473 6.905C70.5517 6.11508 70.1538 5.17535 70.1538 4.0858C70.1538 2.96901 70.5517 2.01566 71.3473 1.22574C72.143 0.408579 73.0896 0 74.1871 0C75.3121 0 76.2724 0.408579 77.0681 1.22574C77.8912 2.01566 78.3028 2.96901 78.3028 4.0858C78.3028 5.17535 77.8912 6.11508 77.0681 6.905C76.2724 7.69493 75.3121 8.08989 74.1871 8.08989ZM77.9735 30.6027H70.4831V10.7865H77.9735V30.6027Z" fill="#25211A"/>
              <path d="M100.141 10.8274V31.2564C100.141 32.9452 99.6882 34.4433 98.7828 35.7508C97.8773 37.0855 96.6015 38.1205 94.9552 38.856C93.3089 39.6187 91.3746 40 89.1521 40C88.7406 40 88.1507 39.9591 87.3824 39.8774C86.6416 39.7957 85.8184 39.6595 84.913 39.4688C84.035 39.3054 83.157 39.0739 82.279 38.7743C81.4284 38.4746 80.6739 38.1205 80.0154 37.712L82.279 32.4821C83.6234 33.1358 84.8032 33.5444 85.8184 33.7079C86.8611 33.8985 87.794 33.9939 88.6171 33.9939C89.7969 33.9939 90.7572 33.7215 91.498 33.1767C92.2663 32.6592 92.6504 32.0054 92.6504 31.2155V30.5209C92.184 30.6844 91.7038 30.8069 91.21 30.8887C90.7161 30.9976 90.2085 31.0521 89.6872 31.0521C87.7391 31.0521 85.9831 30.6844 84.4191 29.9489C82.8826 29.2135 81.6616 28.0831 80.7562 26.5577C79.8782 25.0051 79.4392 23.0575 79.4392 20.715C79.4392 18.3453 79.8782 16.3841 80.7562 14.8315C81.6616 13.2516 82.91 12.0667 84.5014 11.2768C86.0928 10.4869 87.9311 10.0919 90.0164 10.0919C90.9493 10.0919 91.8547 10.2417 92.7327 10.5414C93.6382 10.841 94.5299 11.3449 95.4079 12.0531L96.3957 10.8274H100.141ZM87.1766 20.6742C87.1766 22.1723 87.4921 23.3299 88.1232 24.1471C88.7543 24.937 89.6597 25.332 90.8395 25.332C91.3883 25.332 91.9919 25.1277 92.6504 24.7191V16.3841C91.9096 16.0027 91.1962 15.8121 90.5103 15.8121C89.5774 15.8121 88.7817 16.2479 88.1232 17.1195C87.4921 17.9639 87.1766 19.1488 87.1766 20.6742Z" fill="#25211A"/>
              <path d="M110.985 30.6027H103.495V2.20633L110.985 1.75689V11.8488C111.891 11.3858 112.892 10.9908 113.99 10.6639C115.087 10.3098 116.226 10.1328 117.406 10.1328C119.107 10.1328 120.479 10.4733 121.521 11.1542C122.591 11.808 123.373 12.7205 123.867 13.8917C124.388 15.0358 124.649 16.3432 124.649 17.8141V30.6027H117.159V18.5087C117.159 17.8822 117.008 17.3374 116.706 16.8744C116.431 16.3841 115.869 16.1389 115.018 16.1389C114.552 16.1389 113.907 16.2615 113.084 16.5066C112.288 16.7518 111.589 17.0106 110.985 17.2829V30.6027Z" fill="#25211A"/>
              <path d="M141.338 11.6037L142.038 17.4464H136.111V22.3493C136.111 23.1665 136.317 23.8202 136.729 24.3105C137.168 24.7736 137.744 25.0051 138.457 25.0051C138.869 25.0051 139.335 24.9506 139.856 24.8417C140.378 24.7055 140.872 24.5012 141.338 24.2288L143.478 29.2952C143.149 29.5676 142.669 29.84 142.038 30.1124C141.434 30.3847 140.762 30.6027 140.021 30.7661C139.308 30.9568 138.622 31.0521 137.963 31.0521C136.372 31.0521 134.863 30.7661 133.436 30.1941C132.009 29.5948 130.843 28.7096 129.938 27.5383C129.06 26.367 128.621 24.9098 128.621 23.1665V17.4464H125.534V11.6037H128.621V6.25128L136.111 5.22982V11.6037H141.338Z" fill="#25211A"/>
            </svg>
          </Link>
          <nav>
            <a 
              href="https://www.linkedin.com/in/nathan-wright-makes-products/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center w-8 h-8 text-[#25211A] hover:text-[#5E5D5C] transition-colors duration-150"
            >
              <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="currentColor"/>
                <path d="M12.56 10.6953C12.7085 10.5286 12.8309 10.3586 12.9839 10.2146C13.4532 9.7714 14.0098 9.54809 14.6582 9.55376C15.0153 9.55716 15.3678 9.5821 15.7113 9.68185C16.498 9.9097 16.9548 10.4504 17.1725 11.219C17.3357 11.7948 17.3663 12.3866 17.3675 12.9794C17.3697 14.2309 17.3641 15.4823 17.3675 16.7338C17.3675 16.8506 17.3346 16.8812 17.219 16.8812C16.574 16.8755 15.929 16.8755 15.2851 16.8812C15.1717 16.8812 15.1468 16.8472 15.1479 16.7395C15.1513 15.5481 15.1525 14.3578 15.1479 13.1665C15.1479 12.8683 15.1275 12.5702 15.0448 12.28C14.8906 11.745 14.5075 11.4729 13.9475 11.5024C13.1834 11.5432 12.7856 11.9218 12.6881 12.6994C12.6643 12.8853 12.6541 13.0712 12.6541 13.2583C12.6541 14.4168 12.6529 15.5742 12.6575 16.7327C12.6575 16.8483 12.6291 16.8823 12.5112 16.8812C11.8617 16.8755 11.2122 16.8766 10.5626 16.8812C10.4583 16.8812 10.4289 16.854 10.4289 16.7485C10.4323 14.4553 10.4323 12.1621 10.4289 9.86889C10.4289 9.75553 10.4663 9.72946 10.5728 9.72946C11.1895 9.73399 11.8062 9.73513 12.4228 9.72946C12.5362 9.72946 12.5656 9.76573 12.5634 9.87229C12.5566 10.1466 12.5611 10.4209 12.5611 10.6941L12.56 10.6953Z" fill="#FDFDFD"/>
                <path d="M9.02779 13.315C9.02779 14.4497 9.02552 15.5844 9.03006 16.7179C9.03006 16.8438 8.99945 16.8823 8.86909 16.8812C8.22408 16.8744 7.58021 16.8755 6.93521 16.8812C6.83206 16.8812 6.80032 16.8562 6.80145 16.7497C6.80485 14.4531 6.80485 12.1565 6.80145 9.8587C6.80145 9.68527 6.8774 9.71474 7.36144 9.78729C7.77972 9.84963 8.20708 9.84283 8.6231 9.76462C8.99265 9.69547 9.03006 9.72607 9.03006 9.88477C9.02665 11.0285 9.02779 12.1723 9.02779 13.3161V13.315Z" fill="#FDFDFD"/>
                <path d="M9.20573 7.45327C9.20573 8.46102 8.04949 9.20464 6.99754 8.41567C6.14962 7.36825 6.90458 6.17233 7.92026 6.17233C8.62421 6.17233 9.2046 6.75159 9.20573 7.45327Z" fill="#FDFDFD"/>
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;