import React from 'react';

export const ColesGroupLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="22" viewBox="0 0 113 22" fill="none" className="w-[113px] h-full">
    <g clipPath="url(#clip0_1613_7385)">
      <path d="M13.7463 9.00617C13.3726 7.54222 12.4714 6.26388 11.2121 5.41159C9.95293 4.5593 8.42252 4.19179 6.90874 4.37819C5.39497 4.56459 4.00216 5.29205 2.99228 6.42375C1.9824 7.55544 1.42505 9.01338 1.42505 10.5234C1.42505 12.0333 1.9824 13.4913 2.99228 14.623C4.00216 15.7547 5.39497 16.4821 6.90874 16.6685C8.42252 16.8549 9.95293 16.4874 11.2121 15.6351C12.4714 14.7828 13.3726 13.5045 13.7463 12.0405H11.1065C10.7556 12.8172 10.146 13.4511 9.3797 13.8364C8.61336 14.2217 7.73665 14.335 6.89606 14.1574C6.05547 13.9798 5.30191 13.5221 4.76127 12.8606C4.22064 12.1992 3.92568 11.3742 3.92568 10.5234C3.92568 9.67254 4.22064 8.8475 4.76127 8.18607C5.30191 7.52464 6.05547 7.06688 6.89606 6.88928C7.73665 6.71167 8.61336 6.82499 9.3797 7.21028C10.146 7.59557 10.7556 8.2295 11.1065 9.00617H13.7463Z" fill="#5E5D5C"/>
      <path d="M26.8207 10.5248C26.821 9.29997 26.4543 8.10255 25.7671 7.08399C25.0799 6.06542 24.1029 5.27146 22.9598 4.80252C21.8167 4.33358 20.5588 4.21072 19.3452 4.44949C18.1315 4.68825 17.0167 5.2779 16.1416 6.14388C15.2665 7.00986 14.6706 8.11326 14.429 9.31454C14.1875 10.5158 14.3113 11.761 14.7848 12.8926C15.2582 14.0243 16.0601 14.9915 17.0889 15.672C18.1177 16.3525 19.3273 16.7158 20.5647 16.7158C22.2236 16.7159 23.8147 16.0637 24.9879 14.9027C26.1611 13.7417 26.8204 12.1669 26.8207 10.5248ZM24.3198 10.5248C24.3198 11.2596 24.0996 11.978 23.6872 12.589C23.2747 13.2 22.6885 13.6762 22.0027 13.9574C21.3168 14.2386 20.5621 14.3122 19.834 14.1688C19.1059 14.0255 18.4371 13.6716 17.9122 13.152C17.3872 12.6324 17.0298 11.9704 16.8849 11.2496C16.7401 10.5289 16.8144 9.78188 17.0985 9.10298C17.3826 8.42407 17.8637 7.84381 18.481 7.43555C19.0982 7.0273 19.8239 6.80939 20.5663 6.80939C21.5614 6.80995 22.5157 7.20162 23.2192 7.89832C23.9227 8.59502 24.318 9.53973 24.3182 10.5248" fill="#5E5D5C"/>
      <path d="M30.3736 0.633057H27.6322V16.4128H30.3736V0.633057Z" fill="#5E5D5C"/>
      <path d="M43.046 11.0815C43.0625 10.8793 43.0726 10.6733 43.0715 10.4626C43.0784 5.88027 39.5596 4.33122 37.129 4.33069C34.6978 4.33069 31.18 5.87764 31.1864 10.4626C31.18 14.2644 33.9709 16.7163 37.1306 16.7157C38.4091 16.7194 39.6538 16.3088 40.6746 15.5468C41.6953 14.7847 42.4362 13.713 42.7842 12.4952H40.0933C39.8236 13.0483 39.4035 13.5161 38.8802 13.846C38.3568 14.1759 37.751 14.3549 37.1306 14.3629C35.4747 14.3629 33.9911 13.0988 33.7309 11.0794H40.5302L43.046 11.0815ZM37.1284 6.56025C37.8615 6.55985 38.5746 6.79624 39.1596 7.23353C39.7445 7.67081 40.1693 8.2851 40.3695 8.98312H33.8895C34.0897 8.2851 34.5145 7.67081 35.0994 7.23353C35.6844 6.79624 36.3975 6.55985 37.1306 6.56025" fill="#5E5D5C"/>
      <path d="M48.5122 16.6871C45.3504 16.6871 43.5365 15.1797 43.5365 12.5498V12.4971H46.0119V12.5498C46.1183 13.9503 47.0101 14.6314 48.732 14.6314C50.1639 14.6314 51.0541 14.052 51.0541 13.1213C51.0541 12.3312 50.4486 12.0357 49.2572 11.7413L47.5097 11.3036C46.5193 11.1264 45.5836 10.7249 44.7758 10.1306C44.468 9.86434 44.226 9.53172 44.0685 9.15835C43.9109 8.78497 43.842 8.3808 43.8669 7.97692C43.8523 7.47844 43.9536 6.9833 44.1629 6.5297C44.3722 6.0761 44.6841 5.67616 45.0743 5.36075C46.0494 4.65705 47.2389 4.30568 48.4441 4.36527C49.6982 4.30674 50.9356 4.66881 51.956 5.39288C52.3684 5.7157 52.6992 6.12911 52.9222 6.60037C53.1452 7.07164 53.2544 7.58782 53.241 8.10807V8.13019H50.788V8.10912C50.7221 6.97775 49.8808 6.37888 48.3552 6.37888C47.1548 6.37888 46.4093 6.84871 46.4093 7.60453C46.4093 8.44727 47.1474 8.7812 48.3382 9.04983L49.8196 9.37849C52.255 9.93839 53.5741 10.6516 53.5741 12.8142C53.5944 13.3565 53.4857 13.896 53.2566 14.389C53.0276 14.8821 52.6847 15.3149 52.2556 15.6527C51.1562 16.3965 49.8397 16.7607 48.5101 16.6887" fill="#5E5D5C"/>
      <path d="M56.1301 10.4776C56.1123 11.0968 56.2228 11.7131 56.4548 12.2885C56.6868 12.8638 57.0354 13.386 57.4791 13.8229C57.9228 14.2598 58.4522 14.6021 59.0347 14.8287C59.6171 15.0553 60.2403 15.1614 60.8658 15.1405C61.4809 15.151 62.0916 15.0371 62.6607 14.8059C63.2298 14.5747 63.7452 14.231 64.1755 13.7959C65.0144 12.8979 65.4832 11.7217 65.4898 10.4987C65.4814 9.39014 65.104 8.3153 64.416 7.44057C63.9896 6.90721 63.4415 6.48158 62.8167 6.19866C62.1918 5.91573 61.5081 5.78357 60.8216 5.81304C60.2019 5.79914 59.5858 5.90973 59.0105 6.13812C58.4351 6.36651 57.9125 6.70797 57.4742 7.14186C57.036 7.57576 56.6911 8.09309 56.4604 8.66261C56.2297 9.23214 56.1181 9.84203 56.1322 10.4555L56.1301 10.4776ZM67.0436 14.9678C67.0436 17.18 66.3199 18.59 65.2876 19.566C64.0814 20.7042 62.4843 21.3477 60.8179 21.3668C57.8812 21.3668 55.7336 19.6966 54.9887 17.5919H56.8511C57.1904 18.2068 57.6706 18.7344 58.2532 19.1325C59.0165 19.6677 59.9252 19.9625 60.8605 19.9784C61.47 19.9775 62.0731 19.8558 62.6343 19.6206C63.1956 19.3854 63.7036 19.0413 64.1284 18.6087C64.5532 18.1761 64.8863 17.6638 65.1079 17.1018C65.3296 16.5398 65.4354 15.9396 65.419 15.3365V13.8396C64.9827 14.7097 64.3018 15.4365 63.4583 15.9329C62.6147 16.4292 61.6443 16.6738 60.6636 16.6375C59.8461 16.6559 59.0333 16.5094 58.2749 16.2069C57.5164 15.9044 56.8281 15.4521 56.2519 14.8778C55.6757 14.3034 55.2237 13.619 54.9233 12.8661C54.623 12.1132 54.4807 11.3077 54.505 10.4987C54.4764 9.68646 54.6155 8.87702 54.9137 8.11985C55.2119 7.36268 55.6631 6.67369 56.2396 6.09503C56.816 5.51637 57.5057 5.0602 58.2664 4.75442C59.0271 4.44864 59.8428 4.29968 60.6636 4.31666C61.6384 4.2848 62.6028 4.52404 63.4472 5.00722C64.2916 5.49041 64.9823 6.1982 65.4409 7.05028V4.64216H67.0436V14.9678Z" fill="#5E5D5C"/>
      <path d="M97.5547 16.3125H96.0207V14.2299C95.363 15.7484 93.764 16.6596 91.6606 16.6375C87.9784 16.5727 86.6854 14.0998 86.6854 11.2582V4.64221H88.2855V10.9543C88.2855 13.4709 89.4247 15.1627 92.0767 15.1406C94.5313 15.119 95.9334 13.6658 95.9334 11.2366V4.64221H97.5547V16.3125Z" fill="#5E5D5C"/>
      <path d="M109.955 10.4562C109.97 9.8375 109.858 9.22217 109.625 8.64779C109.392 8.07341 109.044 7.55203 108.601 7.11549C108.157 6.67896 107.629 6.33643 107.047 6.10881C106.466 5.8812 105.844 5.77328 105.219 5.79165C104.6 5.78945 103.987 5.90916 103.415 6.14381C102.843 6.37847 102.324 6.72338 101.888 7.15847C101.032 8.06334 100.561 9.25983 100.573 10.4994C100.562 11.1121 100.675 11.7209 100.907 12.2892C101.138 12.8576 101.483 13.3738 101.921 13.8072C102.359 14.2405 102.88 14.582 103.454 14.8112C104.029 15.0405 104.644 15.1527 105.263 15.1413C105.883 15.1527 106.5 15.0398 107.076 14.8092C107.652 14.5786 108.174 14.2352 108.612 13.7996C109.05 13.3639 109.395 12.8451 109.625 12.2742C109.856 11.7034 109.967 11.0923 109.953 10.4778L109.955 10.4562ZM99.063 4.64237H100.597V7.07209C101.083 6.20764 101.8 5.49285 102.67 5.00664C103.54 4.52043 104.529 4.28172 105.528 4.31687C109.494 4.31687 111.577 7.50557 111.577 10.521C111.577 13.7745 109.187 16.6382 105.528 16.6382C102.921 16.6382 101.233 15.1413 100.686 13.9262V20.7381H99.0646L99.063 4.64237Z" fill="#5E5D5C"/>
      <path d="M79.3391 4.31689C78.1103 4.31679 76.9091 4.67738 75.8873 5.35308C74.8655 6.02877 74.0691 6.98921 73.5988 8.11294C73.1285 9.23668 73.0054 10.4732 73.245 11.6662C73.4847 12.8592 74.0764 13.9551 74.9453 14.8152C75.8141 15.6753 76.9211 16.2611 78.1263 16.4984C79.3315 16.7358 80.5808 16.614 81.716 16.1485C82.8513 15.6831 83.8217 14.8948 84.5044 13.8835C85.1871 12.8721 85.5515 11.6831 85.5515 10.4668C85.5515 8.83581 84.897 7.27164 83.7319 6.11833C82.5669 4.96503 80.9868 4.31703 79.3391 4.31689ZM79.3391 5.87596C80.2564 5.87596 81.1531 6.1452 81.9158 6.64965C82.6785 7.15409 83.2729 7.87108 83.6239 8.70994C83.975 9.5488 84.0668 10.4719 83.8879 11.3624C83.7089 12.2529 83.2672 13.0709 82.6186 13.7129C81.97 14.355 81.1436 14.7922 80.2439 14.9694C79.3443 15.1465 78.4118 15.0556 77.5643 14.7081C76.7169 14.3606 75.9925 13.7722 75.4829 13.0173C74.9733 12.2623 74.7013 11.3747 74.7013 10.4668C74.7027 9.24963 75.1918 8.08275 76.0612 7.22211C76.9307 6.36147 78.1095 5.87735 79.3391 5.87596Z" fill="#5E5D5C"/>
      <path d="M75.5104 4.32216L75.5178 4.31689H73.8683C72.4848 4.36008 70.9534 4.81569 70.0989 6.5512V4.6424H68.5648V15.767H70.1643V9.95638C70.1643 7.32283 71.4244 5.91493 73.6826 5.90071C74.1991 5.2777 74.8168 4.74422 75.5104 4.32216Z" fill="#5E5D5C"/>
      <path d="M66.8802 19.8472C66.6939 20.0882 66.4903 20.3157 66.2709 20.5277C66.1943 20.6019 66.1113 20.6678 66.0288 20.7384H97.779V19.8472H66.8802Z" fill="#5E5D5C"/>
    </g>
    <defs>
      <clipPath id="clip0_1613_7385">
        <rect width="113" height="20.7339" fill="white" transform="translate(0 0.633057)"/>
      </clipPath>
    </defs>
  </svg>
);